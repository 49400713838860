.questionAsImage {
    width: 100%;
    object-fit: contain;
    /* height: 100%; */
}

/* media query for web*/
@media screen and (max-width: 767px) {  
    .questionAsImage {
        width: 100%;
        min-width: 440px;
    }
}

@media screen and (min-width: 768px) {
    .questionAsImage {
        width: 100%;
        min-width: 440px;
    }
    
}